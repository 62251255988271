import React from 'react'
import { connect } from 'react-redux'
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import {usuarioLogado, buscaUsuario} from '../../utils/userUtils'


export const Menu = ({user}) => { 
    const usuario = buscaUsuario(usuarioLogado(user))
    return ( 
        <ul className='sidebar-menu'>
        {(usuario.orcamento || usuario.saldo) &&    
            <MenuTree label='Relatórios' icon='bar-chart'> 
                {usuario.orcamento && 
                <MenuItem path='orcamento' label='Orçamento' icon='usd' />
            }
                {usuario.saldo && 
                <MenuItem path='saldo' label='Saldo dos Bancos' icon='usd' />   
            }
            </MenuTree>
    }
        </ul>
     )
}
const mapStateToProps = state => ({ user: state.auth.user })
export default connect(mapStateToProps)(Menu)