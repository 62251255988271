import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

//import Dashboard from '../dashboard/dashboard'
import Orcamento from '../orcamento'
import Saldo from '../saldo'

export default props => (
    <div className='content-wrapper'>
        <Switch>
            <Route exact path='/' component={Orcamento} />
            <Route path='/orcamento' component={Orcamento} />
            <Route path='/saldo' component={Saldo} />
            <Redirect from='*' to='/' />
        </Switch>
    </div>
)