const MappingRequests = {
    400: { message: 'Ocorreu um erro no envio da requisição.', type: 'error' },
    401: { message: 'Você precisa estar autenticado para concluir essa requisição.', type: 'error' },
    403: { message: 'Você não tem permissão para acessar esta informação.', type: 'error' },
    404: { message: 'Não existem informações para serem exibidas.', type: 'info' },
    409: { message: 'A requisição já esta sendo executada. Aguarde...', type: 'warning' },
    415: { message: 'Tipo de requisição não suportada pelo servidor', type: 'error'},
    429: { message: 'Unknown Error', type: 'error' },
    499: { message: 'Client Closed Request', type: 'error' },
    500: { message: 'Ocorreu um erro na comunicação com o servidor.', type: 'error' },
    501: { message: 'Unknown Error', type: 'error' },
    503: { message: 'O serviço está indisponível. Tente novamente em instantes.', type: 'error' },
    504: { message: 'Tempo de resposta excedido. Tente novamente.', type: 'error' },
      0: { message: 'Ocorreu um erro na comunicação com o servidor.', type: 'error' },
}

export const errors = error => {
    if (error.response) {
        if (MappingRequests[error.response.status]) {
            return MappingRequests[error.response.status];
        }
        return { message: 'Unknown Error', type: 'error' };
    } else if (error.request) {
        if (MappingRequests[error.request.status]) {
            return MappingRequests[error.request.status];
        }
        return { message: 'Unknown Error', type: 'error' };
    }
    return MappingRequests[error.message];
}

export default errors;