import React from 'react'
import "../common/template/custom.css"

export default (props) => {
    return(

        <div className="container">
        <iframe className="responsive-iframe" frameBorder="0" src={props.link} allowFullScreen={true} ></iframe>
        </div>
      
    )
}