import './auth.css'
import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from './authActions'
import Row from '../common/layout/row'
import Grid from '../common/layout/grid'
import Messages from '../common/msg/messages'
import Input from '../common/form/inputAuth'
class Auth extends Component {
    
    onSubmit(values) {
        const { login } = this.props
        login(values)
    }
    render() {
        const { handleSubmit } = this.props
        return (
            <div className="login-box">
                <div className="login-logo"><b> Gestão</b> Protheus</div>
                <div className="login-box-body">
                    <p className="login-box-msg">Bem vindo!</p>
                    <form onSubmit={handleSubmit(v => this.onSubmit(v))}>
                        <Field component={Input} type="email" name="email"
                            placeholder="E-mail" icon='envelope' />
                        <Field component={Input} type="password" name="password"
                            placeholder="Senha" icon='lock' />
                        <Row>
                            <Grid cols="4">
                                <button type="submit"
                                    className="btn btn-primary btn-block btn-flat">
                                    {'Entrar'}
                                </button>
                            </Grid>
                        </Row>
                    </form>
                    <br />
                </div>
                <Messages />
            </div>

        )
    }
}

Auth = reduxForm({ form: 'authForm' })(Auth)
const mapDispatchToProps = dispatch => bindActionCreators({ login },
    dispatch)
export default connect(null, mapDispatchToProps)(Auth)