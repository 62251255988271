import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import { errors } from '../common/msg/handleErrorMessage'

export function login(values) {
    return submit(values, `${process.env.REACT_APP_OAPI_URL}/rest/api/oauth2/v1/token`)
}

function submit(values, url) {
    let user = {
        user: {
            email: values.email
        },
        token: {}
    }
    let config = {
        method: 'post',
        url,
        headers: { 
            'grant_type': 'password',
            username: values.email,
            password: values.password
        }
      }
      return dispatch => {
        axios(config)
            .then(resp => {
                user.token = resp.data
                dispatch([
                    { type: 'USER_FETCHED', payload: user }                 
                ])
            })
            .catch(error => {
                const e = errors(error);
                toastr.error(e.type, e.message)
                setTimeout(() => {
                    location.reload()
                }, 2000);                              
            })
    }
}

export function logout() {
    return { type: 'TOKEN_VALIDATED', payload: false }
}
export function validateToken(token) {
    return dispatch => {
        if (token) {
            axios.post(`${process.env.REACT_APP_OAPI_URL}/rest/api/oauth2/v1/token?grant_type=refresh_token&refresh_token=${token}`)
                .then(resp => {
                    dispatch({ type: 'TOKEN_VALIDATED', payload: resp.data })
                })
                .catch(e => dispatch({ type: 'TOKEN_VALIDATED', payload: false }))
        } else {
            dispatch({ type: 'TOKEN_VALIDATED', payload: false })
        }
    }
}