import '../common/template/dependencies'
import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import App from './app'
import Auth from '../auth/auth'
import { validateToken } from '../auth/authActions'

class AuthOrApp extends Component {
    componentDidMount() {
        if (this.props.auth.user) {
            this.props.validateToken(this.props.auth.token.refresh_token)
        }
    }
    render() {
        const { user, token, validToken } = this.props.auth
        if (user && token && validToken) {
            axios.defaults.headers.common['authorization'] = token.access_token
            return <App>{this.props.children}</App>
        } else if (!user && !token && !validToken) {
            return <Auth />
        } else {
            return false
        }
    }
}
const mapStateToProps = state => ({ auth: state.auth })
const mapDispatchToProps = dispatch => bindActionCreators({ validateToken },
    dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp)