import React, { Component } from 'react'
import { connect } from 'react-redux'
import Iframe from '../iframe/iframe'
import {usuarioLogado, buscaUsuario} from '../utils/userUtils'

class Saldo extends Component {
    constructor(props) {
        super(props)
        this.state = { open: false }
    }   
    render() {
        const usuario = buscaUsuario(usuarioLogado(this.props.user))
        return (
            <div> {usuario ?
            <Iframe link={usuario.saldo}/> :
            <h4>O usuario {usuarioLogado(this.props.user)} não possuí este relatório</h4> }
            </div>
            
        )
    }
}

const mapStateToProps = state => ({ user: state.auth.user })
export default connect(mapStateToProps)(Saldo)