const userKey = '_gprotheus_user'
const tokenKey = '_gprotheus_token'
const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem(userKey)),
    token: JSON.parse(localStorage.getItem(tokenKey)),
    validToken: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'TOKEN_VALIDATED':
            if (action.payload) {
                return { ...state, token: action.payload, validToken: true }
            } else {
                localStorage.removeItem(userKey)
                localStorage.removeItem(tokenKey)
                return { ...state, validToken: false, user: null, token: null}
            }
        case 'USER_FETCHED':
            localStorage.setItem(userKey, JSON.stringify(action.payload.user))
            localStorage.setItem(tokenKey, JSON.stringify(action.payload.token))
            return { ...state, user: action.payload.user, token: action.payload.token, validToken: true }
        default:
            return state
    }
}
