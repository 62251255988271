import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import AuthReducer from '../auth/authReducer'
import { reducer as toastrReducer } from 'react-redux-toastr'

const rootReducer = combineReducers({
    auth: AuthReducer,
    toastr: toastrReducer,
    form: formReducer
  })

  export default rootReducer